import React, {useState, useEffect, useRef} from 'react'
import Nouislider from "nouislider-react";
import '../css/video.css'
import { updateObjInTimeline } from '../utils/timelineUtils';
import "nouislider/distribute/nouislider.css";
import { useDispatch, useSelector } from 'react-redux'

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

var animationFrameId;

const VideoTrim = ({handleClose}) => {

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )  

    const selectedObjectId = useSelector(
        (state) => state.storyReducer.selectedObjectId
    )

    const videoRef = useRef()
    const sliderRef = useRef()

    const pinRef = useRef()
    const progressBarRef = useRef()

    const isPlayingRef = useRef(false)
    const currentTimeRef = useRef()

    const valueInputRef = useRef()
    const valueSpanRef = useRef()

    const [videoInfo, setVideoInfo] = useState()
    const [sliderStartValue, setSliderStartValue] = useState()
    const [sceneDuration, setSceneDuration] = useState(3.5)
    const [currentTime, setCurrentTime] = useState()
    const [playing, setPlaying] = useState(false)

    const [isDragging, setIsDragging] = useState(false);

    const pinPosition = 0;


    useEffect(() => {
        if(selectedObjectId !== null || selectedObjectId !== undefined){
            console.log('SELECTED VIDEO OBJ ID::', selectedObjectId)

            var currentObj = getObjectFromId(selectedObjectId)
            if(currentObj && currentObj.type == 'video'){
                console.log('CURR VIDEO OBJ: ', currentObj )
                var startTime = currentObj.videoStartTime ?? currentObj.startTime;
                var endTime = currentObj.videoStartTime ? 
                                (currentObj.endTime - currentObj.startTime) + currentObj.videoStartTime : 
                                currentObj.endTime;

                var videoStartTime = currentObj.videoStartTime ?? 0
                setSliderStartValue(videoStartTime)
    
                setVideoInfo(currentObj.info)
    
                valueSpanRef.current = [startTime, endTime]
                currentTimeRef.current = startTime

                if(valueInputRef){
                    valueInputRef.current.innerHTML = `${toHHMMSS(videoStartTime)}/${toHHMMSS(currentObj?.info?.duration)}`;
                }
            }
           
        }
    }, [selectedObjectId])

    const getObjectFromId = (objId) => {
        console.log('TIMELINE: ', timeline)
          for (let i = 0; i < timeline.length; i++) {
            var obj = timeline[i];
            if (obj.id == objId) {
              return obj;
            }
        }
  
        return null;
      }

    const onSliderUpdate = (evt) => {
        videoRef.current.currentTime = parseFloat(evt[0]) + 0.001;
        const startTime = parseFloat(evt[0])
        valueInputRef.current.innerHTML = `${toHHMMSS(startTime)}/${toHHMMSS(videoInfo.duration)}`;
    }

    const onSliderChange = async (evt) => {
        console.log('SLIDER MOVED: ', evt)
        if(valueInputRef.current && videoInfo){
            valueInputRef.current.innerHTML = `${toHHMMSS(evt[0])}/${toHHMMSS(videoInfo.duration)}`;
        }
        
        // videoRef.current.pause()
        // await delay(150)

        const startTime = parseFloat(evt[0])
        const endTime = parseFloat(evt[1])
        currentTimeRef.current = startTime;
        valueSpanRef.current = [startTime, endTime]

        videoRef.current.currentTime = parseFloat(evt[0]) + 0.001;
        // videoRef.current.play()
        // videoRef.current.currentTime = parseFloat(evt[0]) + 0.001;

        // setPlaying(true);

        // animationFrameId = requestAnimationFrame(render);

        valueInputRef.current.innerHTML = `${toHHMMSS(evt[0])}/${toHHMMSS(videoInfo.duration)}`;
    }

    const render = (t1) => {
        console.log('VAL SPAN: ', valueSpanRef.current)
        if( videoRef.current.currentTime <= valueSpanRef.current[1]){
            console.log('CURR TIME: ', videoRef.current.currentTime)
            var currentTime = videoRef.current.currentTime
            currentTimeRef.current = currentTime
            valueInputRef.current.innerHTML = `${toHHMMSS(currentTime)}/${toHHMMSS(videoInfo.duration)}`;
            animationFrameId = requestAnimationFrame(render);
            
        }else{
            console.log("Video Ended")
            videoRef.current.pause()
            valueInputRef.current.innerHTML = `${toHHMMSS(valueSpanRef.current[0])}/${toHHMMSS(videoInfo.duration)}`;

            setPlaying(false);
            isPlayingRef.current = false;
            currentTimeRef.current = null;
            cancelAnimationFrame(animationFrameId);
           
        }
        
    }

    const toggleVideoPlay = () => {
        if(playing){
            console.log('Video Paused', )
            videoRef.current.pause();
            isPlayingRef.current = false;
            setPlaying(false);
        }else{
            setPlaying(true);
            console.log('Video Play: ', currentTimeRef.current ?? valueSpanRef.current[0])
            videoRef.current.currentTime = currentTimeRef.current ?? valueSpanRef.current[0]
            videoRef.current.play();
            animationFrameId = requestAnimationFrame(render);
        }
    }

    function delay(time) {
        return new Promise(function (resolve) {
          setTimeout(resolve, time);
        });
    }

  

    function trimVideo(){
        if(selectedObjectId !== null || selectedObjectId !== undefined){
           
            var newInfo = {videoStartTime: valueSpanRef.current[0]}
            console.log('SELECTED OBJ: ', selectedObjectId)
            console.log('UPDATING TIMELINE OBJ: ', newInfo)
            updateObjInTimeline(timeline, selectedObjectId, newInfo)
            handleClose()
        }
    }


    function toHHMMSS(val) {
        var sec_num = parseFloat(val, 10);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        seconds = seconds.toFixed(1)
      
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
      
        // only mm:ss
        if (hours == "00"){
          var time = minutes+':'+seconds;
        }
        else{
          var time = hours+':'+minutes+':'+seconds;
        }
      
        return time;
      }

    return (
        <>
            <div style={{paddingTop: 30}}>
                {
                    videoInfo && 
                    <div class="embed-responsive embed-responsive-16by9">
                        <video
                            ref={videoRef}
                            width='712px'
                            height='400px'
                            controls={false}
                            src={videoInfo.url}
                        />
                    </div>
                }

                <div style={{width: '100%'}}>
                    
                </div>
                <div class="row" style={{width: 600, marginTop: 30, marginBottom: 30}}>
                    <div class="col-4 text-left">
                        <div>
                            <span onClick={toggleVideoPlay}>
                                { 
                                    playing ? 
                                        <PauseRoundedIcon style={{ fontSize: 45}} />
                                        : <PlayArrowRoundedIcon style={{ fontSize: 45}}/>
                                }
                            </span>
                            <span ref={valueInputRef} style={{fontSize: 16, marginTop: 15, fontWeight: 700, opacity: 0.8}}>
                                        
                            </span>
                            
                        </div>
                        
                    </div>
                    <div class="col-md-7">
                        {
                            videoInfo && 
                                <div  style={{margin: 'auto', width: '100%', position: 'relative', marginTop: 10 }}>
                                    <Nouislider
                                        ref={sliderRef}
                                        step={0.1} 
                                        margin={3}
                                        start={[sliderStartValue, sliderStartValue+sceneDuration]}
                                        range={{ min: 0, max: videoInfo.duration }}
                                        behaviour='drag-fixed'
                                        connect 
                                        onChange={onSliderChange}
                                        onUpdate={onSliderUpdate}
                                    />
                                </div>
                        }
                    </div>
                    <div class="col-md-1 text-right">
                        <div className='video-trim-btn' onClick={trimVideo}>Trim</div>
                    </div>
                </div>
            
            </div>
        
        </>
    )
}

export default VideoTrim
