import React, {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import '../css/input.css'
import { useDispatch, useSelector } from 'react-redux'
import { setSettingsModalActive } from '../redux/storySlice';
import { captions } from '../constants/captionStyles'; 
import { availableLanguages, voices } from '../constants/speechConfig';
import SettingsModalView from '../components/Dialogs/SettingsModalView';

import uuid from 'react-uuid';


const InputView = ({projectInfo}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const audioRef = useRef()
    const inputRef = useRef()

    const [isPlaying, setIsPlaying] = useState(false)

    const [textBlob, setTextBlob] = useState('')
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [selectedVoice, setSelectedVoice] = useState(null)

    const [activeVoice, setActiveVoice] = useState()
    const [language, setLanguage] = useState()
    const [langCode, setLangCode] = useState('')

    const [voiceOptions, setVoiceOptions] = useState([])
    const [langauageOptions, setLangauageOptions] = useState()

    const [selectedCaptionIdx, setSelectedCaptionIdx] = useState(0)

    const [storyInfo, setStoryInfo] = useState({})
    const [projectId, setProjectId] = useState(uuid())

    const [aspectRatio, setAspectRatio] = useState('1/1')

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const isSettingsModalActive = useSelector(
        (state) => state.storyReducer.isSettingsModalActive
    )

    const genderOptions = [
        {value: 'All', label: 'All'},
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'}
    ]

    const Icon = () => {
        return (
          <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        );
      };

    useEffect(() => {
        setStoryInfo({...storyInfo, projectId, textBlob, selectedVoice, langCode, selectedCaptionIdx})
    }, [textBlob, selectedVoice, langCode, selectedCaptionIdx])

    useEffect(() => {
        if(projectInfo){
            setLanguage(projectInfo?.audioInfo?.voice?.LocaleName)
            setSelectedVoice(projectInfo?.audioInfo?.voice)
            setTextBlob(projectInfo?.text)

            setSelectedCaptionIdx(projectInfo?.captionId)
        }
    }, [projectInfo])

    useEffect(() => {
        console.log('PROJECT INFO: ', projectInfo)
        if(!langauageOptions && availableLanguages){
            var voiceList = []
            availableLanguages.map((lang) => {
                voiceList.push({label: lang, value: lang})
            })
            setLangauageOptions(voiceList)
            getEnglishVoices()
        }
    }, [])

    useEffect(() => {
        const handler = (e) => {
          if (inputRef.current && !inputRef.current.contains(e.target)) {
            setMenuOpen(false);
          }
        };
    
        window.addEventListener("click", handler);
        return () => {
          window.removeEventListener("click", handler);
        };
    });

    useEffect(() => {
        if(storySettings?.aspectRatio){
            setAspectRatio(storySettings.aspectRatio)
        }
    }, [storySettings?.aspectRatio])


    const openSettingsModal = () => {
        console.log('OPEN SETTINGS MODAL')
        dispatch(
            setSettingsModalActive({isActive: true})
        )
    }

    const handleInput = (e) => {
        const value = e.target.value
        setTextBlob(value)
    }

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen)
    }

    const selectVoice = (voice) => {
        console.log('VOICE SELECTED: ', voice)
        setSelectedVoice(voice)
        setMenuOpen(false)
      
        if(isPlaying){
            var audioElm = audioRef.current
            setIsPlaying(false)
            audioElm.pause()
        }
    }

    const handleClick=(e, voice)=>{
        e.stopPropagation();
        console.log('PLAYING AUDIO')
        var audioElm = audioRef.current
        if(isPlaying && activeVoice == voice.ShortName){
            setIsPlaying(false)
            audioElm.pause()
        }else{
          setIsPlaying(true)
          setActiveVoice(voice.ShortName)

          audioElm.src = `https://res.cloudinary.com/dlmsxlf2n/video/upload/v1723737009/Diveo/Assets/audio/voice/${voice.ShortName}.wav`;
          audioElm.volume = 0.5
          audioElm.play()
        }
    }


    const endMusic = (e) => {
        setIsPlaying(false)
    }

    const getVoiceOptions = (language) => {
        console.log('LANG: ', language)
        var voiceList = voices.filter((obj) => {
            return language == obj.LocaleName;
        });
        setVoiceOptions(voiceList)
        setSelectedVoice(voiceList[0])
        console.log('DiSPLAY VOICES: ', voiceList)
    }

    const getEnglishVoices = () => {
        var voiceList = voices.filter((obj) => {
            return obj.Personality != null;
        });
        console.log('ENGLISH VOICES COUNT: ', voiceList.length)
        setVoiceOptions(voiceList)
        setSelectedVoice(voiceList[0])
    }

    const selectLanguage = (lang) => {
        console.log('LANGUAGE SELECTED: ', lang)
        setLanguage(lang.value)

        var languageCode = getLanguageCode(lang.value)
        console.log('LANG CODE: ', languageCode)
        setLangCode(languageCode)

        getVoiceOptions(lang.value)
    }

    const selectCaption = (captionIdx) => {
        setSelectedCaptionIdx(captionIdx)
    }

    const getLanguageCode = (language) => {
        var languageCode;
        for(let i=0; i < voices.length; i++){
            const voice = voices[i]
            if(voice.LocaleName == language){
                languageCode = voice.Locale;
                break;
            }
        }
        return languageCode;

    }

    const closeSettingsModal = () => {
        dispatch(setSettingsModalActive({isActive: false}))
    }

    return (
        <div style={{height: '90vh', width: '100%'}}>
            <div style={{width: '95%', height: '90vh', margin: 'auto', borderRadius: 10, backgroundColor: '#fff'}}>
                <audio ref={audioRef} onEnded={endMusic}></audio>

                <div style={{ height: '10vh', marginTop: 20,}}>
                    <div style={{float: 'left', marginLeft: 20, marginTop: 10, color: '#3b3b3b', fontSize: 24, fontWeight: 700, opacity: 0.9}}>New Project</div>
                    <div className='create-btn' onClick={openSettingsModal}>Start Video Creation <i style={{fontSize: 14}} class="bi bi-chevron-right"></i></div>
                </div>
                <div style={{height: '80vh'}}>
                    <div style={{ float: 'left', width: '45%', height: '100%', overflowY: 'scroll'}}>
                        <div style={{ width: '95%', margin: 'auto', height: '100%', marginLeft: 10, padding: 10}}>
                            <span style={{border: '1px solid #584efd', fontSize: 10, color: '#584efd', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 1</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#584efd'}}>Script</div>
                            <div style={{height: '85%', marginTop: 15}}>
                                <textarea
                                    name='test'
                                    className='text-container'
                                    placeholder='Type or paste your script here...'
                                    value={textBlob}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ float: 'left', height: '90%', margin: 10,  border: '1px dashed #eee'}}></div>
                    <div style={{  float: 'left', width: '45%', height: '95%', overflowY: 'scroll'}}>

                        <div style={{ width: '95%', margin: 'auto', marginTop: 20, padding: 10}}>
                            <span style={{border: '1px solid #584efd', fontSize: 10, color: '#584efd', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 2</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#584efd'}}>Voiceover</div>
                                {/* <div style={{marginTop: 15, fontSize: 14, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Language</div>
                                <div style={{ width: '100%', maxWidth: 350, marginRight: 5}}>
                                    <Dropdown
                                        isSearchable={false}
                                        menuSize={'100%'}
                                        placeHolder={language ?? 'Select Language'}
                                        options={langauageOptions}
                                        onChange={(value) => selectLanguage(value)}
                                    />
                                </div> */}
                                <div style={{marginTop: 15, fontSize: 14, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Select Voice</div>
                                <div style={{ width: '100%', marginRight: 5}}>
                                    <div ref={inputRef} style={{width: '100%', maxWidth: 350,  border: '1px solid #eee', cursor: 'pointer',  padding: 8, borderRadius: 4, fontSize: 15, fontFamily: 'PT Sans'}}>
                                        <div style={{width: '100%'}} onClick={toggleMenu}>
                                            {selectedVoice  ? selectedVoice.DisplayName : 'Select Voice'}
                                            <span style={{float: 'right', marginBottom: 5}}>
                                                <Icon />
                                            </span>
                                        </div>
                                       
                                    </div>
                                    <div  style={{ display: isMenuOpen ? 'block' : 'none', width: '100%', maxWidth: 350, maxHeight: 300, border: '1px solid #eee', marginTop: 5, backgroundColor: '#fff', borderRadius: 4, overflow: 'scroll'}} >
                                            { voiceOptions.map((voice) => 
                                                <div className={selectedVoice.DisplayName == voice.DisplayName ? 'dropdown-selected-item' : 'dropdown-menu-item'} onClick={() => selectVoice(voice)}>
                                                    <div className='row'>
                                                        <div className='col-10'>
                                                            <div style={{ fontSize: 18, fontWeight: 700, }}>{voice.DisplayName}</div>
                                                            <div style={{marginBottom: 10}}>{voice.Gender}, {voice.AgeGroup == 'Young' ? 'Young' : 'Adult'}</div>
                                                            <div style={{fontSize: 14}}>
                                                                {
                                                                    voice.Personality.map((feature) => 
                                                                        <span style={{marginRight: 5, marginBottom: 20, padding: 2, paddingInline: 8, backgroundColor: '#584efd', color: '#fff', borderRadius: 10}}>
                                                                            {feature}
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div style={{  fontSize: 26}} onClick={(e)=>handleClick(e, voice)}>
                                                                {
                                                                    isPlaying && activeVoice == voice.ShortName ?
                                                                        <i class="bi bi-stop-circle-fill"></i> :
                                                                        <i class="bi bi-play-fill"></i> 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                      
                                                </div>
                                            )}
                                        </div>
                                    
                                

                                </div>
                        
                        </div>
                        <div style={{display: 'inline-block', width: '100%', marginTop: 20,  border: '1px dashed #eee'}}></div>
                        <div style={{ width: '95%', margin: 'auto', marginTop: 5, padding: 10, height: 170}}>
                            <span style={{border: '1px solid #584efd', fontSize: 10, color: '#584efd', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 3</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#584efd'}}>Caption Presets</div>
                            {
                                captions.map((caption, index) => 
                                    <div style={{display: 'inline-block', width: 129, height: 78, padding: 2, margin: '2%', border: index === selectedCaptionIdx ? '3px solid #5290D6': null, borderRadius: 15, cursor: 'pointer'}} onClick={() => selectCaption(index)}>
                                        <video
                                            autoPlay  
                                            muted
                                            no-controls
                                            loop
                                            style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                                            src={caption.url}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        
                    </div>
                </div>
                
            
                
            </div>
            <SettingsModalView open={isSettingsModalActive} handleClose={closeSettingsModal} aspect={aspectRatio} storyInfo={storyInfo} />

        </div>

        )
}

export default InputView
