import React, {useState, useEffect} from 'react'
import Player from './Player'
import '../css/editor.css';
import UploadView from './UploadView';
import EditOptions from './EditOptions/EditOptions';
import { useDispatch, useSelector } from 'react-redux'
import { setMenuIndexSelected, updateSettings, setAlertDialogInfo, setVideoEditModalActive } from '../redux/storySlice'
import Loader from './Loader';
import StoryOptions from './StoryOptions';
import AudioOptions from './AudioOptions';
import CaptionSettings from './CaptionSettings';
import AspectOptions from './AspectOptions';
import PreviewModalView from './PreviewModalView';
import { menuIndex } from '../constants/editorConfig';
import { getScreenDimensionsForQuality } from '../constants/canvasSettings';
import { useNavigate } from "react-router-dom";
import { syncProjectDetails } from '../services/firebaseStore';
import uuid from 'react-uuid';
import AlertDialog from './Dialogs/AlertDialog';
import SettingsModalView from './Dialogs/SettingsModalView';
import VideoEditModalView from './Dialogs/VideoEditModalView';

const EditorView = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [currentMenuIndex, setCurrentMenuIndex] = useState(0)
    const [isPreviewModalOpen, setPreviewModalOpen] = useState(false)

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    const sceneInfoArray = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    const menuIndexSelected = useSelector(
        (state) => state.storyReducer.menuIndexSelected
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const alertDialogInfo = useSelector(
        (state) => state.storyReducer.alertDialogInfo
    )

    const isVideoEditModalOpen = useSelector(
        (state) => state.storyReducer.isVideoEditModalActive
    )

    useEffect(() => {
        dispatch(setMenuIndexSelected({menuIndexSelected: currentMenuIndex}))
    }, [currentMenuIndex])


    useEffect(() => {
        // API Call For Audio And Timestamps
        
    }, [])

    const openPreviewModal = () => {
        setPreviewModalOpen(true)
    }

    const closePreviewModal = () => {
       setPreviewModalOpen(false)
    }

    const closeVideoEditModal = () => {
        dispatch(setVideoEditModalActive({isActive: false}))
    }


    const openAlertDialog = () => {
        setAlertDialogInfo(true)
    }

    const closeAlertDialog = () => {
        dispatch(setAlertDialogInfo({info: {isOpen: false}}))
    }

 

    const goToMainPage = () => {
        const data = {
            text: audioInfo.text,
            audioInfo: audioInfo,
            captionId: captionStyle.id
        }
        navigate(`/home`, {state: {data: data}});
    }


    const exportPreview = () => {

        const quality = storySettings.quality ?? 720
        const jobId = uuid()
        const fps = storySettings.fps ?? 30
        const aspectRatio = storySettings.aspectRatio ?? '16/9'
        const totalVideoDuration = audioInfo.duration
        const totalFrameCount = Math.floor(totalVideoDuration * fps)
        
        var settings = {...storySettings, quality: quality, aspectRatio: aspectRatio }
        dispatch(updateSettings({ settings: settings }));

        var dims = getScreenDimensionsForQuality(quality, aspectRatio )

        var data = {
            payload: {
                timeline: timeline,
                audioInfo: audioInfo,
                sceneInfo: sceneInfoArray,
                captionStyle: captionStyle,
                settings: {
                    quality: quality,
                    aspectRatio: aspectRatio
                }
            },
            dims: dims,
            projectId: storySettings.projectId,
            jobId: jobId,
            fps: fps, 
            totalDuration: totalVideoDuration,
            totalFrameCount: totalFrameCount
        }

        console.log('Payload for API: ', data)
        
        syncProjectDetails()
        navigate(`/export/${jobId}`, {state: {data: data}});
    }


    const handleSelectionObjectChange = () => {
        switch(menuIndexSelected){
            case menuIndex.STORY_MENU_INDEX:
                return <StoryOptions/>
            case menuIndex.MEDIA_MENU_INDEX:
                return <UploadView/>
            case menuIndex.AUDIO_MENU_INDEX:
                return <AudioOptions/>
            case menuIndex.CAPTIONS_MENU_INDEX:
                return <CaptionSettings/>
            case menuIndex.ASPECT_MENU_INDEX: 
                return <AspectOptions />
            default:
                return <UploadView/>
        }
        
    }

    return (
        <>      
            <div style={{ height: '100%', fontFamily: 'PT Sans'}}>
                {
                    audioInfo ? 
                    <div style={{height: '100%', overflow: 'hidden'}}>
                        <div style={{height: '8vh', width: '100%', backgroundColor: '#ffffff', borderBottom: '1px solid #f5f5f5'}}>
                            <div className='row'>
                                    <div className='col-1'>
                                    <div style={{margin: 5, marginLeft: 15, fontSize: 24, fontStyle: 'normal', fontWeight: 900, color: '#000', padding: 10, cursor: 'pointer'}} onClick={goToMainPage}><i class="bi bi-arrow-left"></i></div>
                                </div>
                                <div className='col-5'>
                                    <div style={{ float: 'left', margin: 10,  color: '#000', padding: 10}}>
                                        <div style={{  fontSize: 16, fontWeight: 600, cursor: 'pointer', color: '#424242', opacity: 0.8}}>
                                            { storySettings.projectName } <span style={{marginLeft: 10, cursor: 'pointer'}}><i class="bi bi-pencil-square"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    {/* <div style={{ marginTop: 20,}}>
                                        <a href='/guide' style={{ fontSize: 18, cursor: 'pointer', color: '#584efd'}} target="_blank">
                                                Guide: How to get started?  
                                        </a>
                                    </div> */}
                                    
                                </div>
                                <div className='col-3'>
                                    <div style={{float: 'right', marginTop: 7, marginRight: 30}}>
                                        <div class='action-btn-primary' onClick={exportPreview}>Export <span style={{marginLeft: 5, fontSize: 14}}><i class="bi bi-upload"></i></span></div>

                                    </div>
                                    <div style={{float: 'right', marginTop: 7, marginRight: 15}}>
                                        <div class='action-btn-secondary' onClick={openPreviewModal}>Preview</div>
                                    </div>
                                    {/* <div style={{float: 'right', marginTop: 7, marginRight: 15}}>
                                        <button class='btn' style={{ padding: 8, paddingInline: 15, backgroundColor: '#fff', border: '1px solid #5b44ed', borderRadius: 8, fontSize: 15, color: '#584efd'}} onClick={syncProjectDetails}><i class="bi bi-save"></i></button>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    
                        <div style={{height: '92vh'}}>
                            
                            <div style={{width: '6%', height: '92vh', float: 'left'}}> 
                                <div className='sidebar'>
                                    <div className={menuIndexSelected == menuIndex.STORY_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(0)} >
                                        <div className='menu-icon'><i class="bi bi-card-text"></i></div>
                                        <div className='menu-item-text'>Story</div>
                                    </div>
                                
                                    <div className={menuIndexSelected == menuIndex.MEDIA_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(2)}>
                                        <div className='menu-icon'><i class="bi bi-images"></i></div>
                                        <div className='menu-item-text'>Media</div>
                                    </div>
                                    <div className={menuIndexSelected == menuIndex.AUDIO_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(3)}>
                                        <div className='menu-icon'><i class="bi bi-music-note"></i></div>
                                        <div className='menu-item-text'>Audio</div>
                                    </div>
                                    <div className={menuIndexSelected == menuIndex.CAPTIONS_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(4)}>
                                        <div className='menu-icon'><i class="bi bi-badge-cc"></i></div>
                                        <div className='menu-item-text'>Captions</div>
                                    </div>
                                    <div className={menuIndexSelected == menuIndex.ASPECT_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(5)} >
                                        <div className='menu-icon'><i class="bi bi-aspect-ratio"></i></div>
                                        <div className='menu-item-text'>Resize</div>
                                    </div>
                                </div>

                            </div>
                            <div style={{width: '27%', height: '92vh', float: 'left', border: '1px solid #eee'}}>
                                <div style={{width: '95%', margin: 'auto', height: '100%'}}>
                                    {handleSelectionObjectChange()}
                                </div>
                            </div>
                            <div style={{ width: '67%', height: '92vh', float: 'left' }}>
                                <EditOptions />
                                {
                                    audioInfo ? <Player /> :  <Loader type="bars" size={70}/>
                                }
                                
                            </div>
                            
                        </div>
                        <PreviewModalView open={isPreviewModalOpen} handleClose={closePreviewModal} />
                        <VideoEditModalView open={isVideoEditModalOpen} handleClose={closeVideoEditModal} />
                        {
                            alertDialogInfo && 
                                <AlertDialog open={alertDialogInfo.isOpen} handleClose={closeAlertDialog} source={alertDialogInfo.source}/>
                        }
                        
                    </div>:
                    <div className='loader-container'>
                        <div style={{position: 'absolute', width: '50%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            <div style={{marginBottom: 100, height: 60, textAlign: 'center',  fontSize: 22, margin: 'auto'}}>Please wait while the video storyboard is being prepared...</div>
                            <div style={{margin: 'auto', marginTop: 30}}>
                                <div className="loading-bar">
                                </div>
                                <div style={{textAlign: 'center', fontSize: 14, opacity: 0.6}}>Rendering...</div>
                            </div>
                        </div>
                        
                    </div>
                }
                

            </div>  
       
            
        </>
    )
}

export default EditorView
