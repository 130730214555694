import { store } from "../redux/store";
import {
  initNewState,
  updateSettings,
  setAudioInfo,
  setCaptionStyle,
} from "../redux/storySlice";
import { processTextToSpeech } from "../services/speech";
import { captions } from "../constants/captionStyles";
import {
  getRelativeYPosition,
  getTextSizeFromAspectRatio,
} from "../constants/canvasSettings";

function initNewProject() {
  store.dispatch(initNewState({}));
}

function createSceneBounds(sentenceRangeMap, audioDuration) {
  var sceneBoundsArray = [];

  sentenceRangeMap.map((obj, index) => {
    const startTime = index == 0 ? 0 : obj.startTime;
    const endTime =
      index == sentenceRangeMap.length - 1
        ? audioDuration
        : sentenceRangeMap[index + 1].startTime;
    var sceneInfo = {
      id: index,
      text: obj.text,
      start: startTime,
      end: endTime,
      duration: endTime - startTime,
    };
    sceneBoundsArray.push(sceneInfo);
  });
  console.log("SCENES: ", sceneBoundsArray);
  return sceneBoundsArray;
}

async function createNewProject(
  text,
  projectId,
  projectName,
  voice,
  langCode,
  captionIdx,
  aspectRatio
) {
  console.log("CREATING NEW PROJECT");
  initNewProject();

  const settings = {
    projectId: projectId,
    projectName: projectName,
    aspectRatio: aspectRatio,
    quality: 720,
  };
  const captionInfo = captions[captionIdx];
  const style = {
    ...captionInfo,
    position: "bottom",
    top: getRelativeYPosition("bottom"),
    fontSize: getTextSizeFromAspectRatio(aspectRatio),
  };
  const result = await processTextToSpeech(projectId, text, voice, langCode);

  console.log("AUDIO INFO: ", result.data);
  if (result) {
    store.dispatch(updateSettings({ settings: settings }));
    store.dispatch(setAudioInfo({ audioInfo: result.data }));
    store.dispatch(setCaptionStyle({ captionStyle: style }));
  }
}

function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

export { initNewProject, createSceneBounds, createNewProject, delay };
