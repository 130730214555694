import React, { useEffect, useState, useRef } from 'react'
import '../css/main.css';
import logo from "../assets/img/Diveo-Logo-Black.png";
import Footer from './Footer';
import PricingPage from './PricingPage';

function Main() {
    const videoRef = useRef()

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.autoplay = true
            videoRef.current.play()
        }
    }, [videoRef.current])


    return (
        <div class='main-container'>
            {/* <header class="header-navbar">
                    <a class="navbar-brand w-8" href="#" data-config-id="brand">
                        <img src={logo}  height="50" alt=""></img>
                    </a>

                    <a href="/new" class="btn mb-0 cursor-pointer text-white d-btn-cta">
                        Free Trial
                    </a>
            </header> */}
            <header class="header-navbar1">
                <nav>
                    <div class="logo">
                        <a href="#"><img src={logo} class='logo' alt=""></img></a>
                    </div>
                    <ul class="menu">
                  
                        <li><a href="#hiw-section-id">How it works?</a></li>
                        <li><a href="#feature-section-id">Features</a></li>
                        
                        <li><a href='/pricing'>Pricing</a></li>
                        <li><a href='/login'>Login</a></li>
                        {/* <li><a href="#">FAQ</a></li> */}
                    </ul>
                    <div class='cta-btn'>
                        <a href="/login" class="text-decoration-none text-white">Try for free</a>
                    </div>
                </nav>
            </header>


            <header class="header headline-section">
                <div class=" p-4 py-10">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8  mt-auto mx-auto text-center justify-content-bottom my-auto">
                                <div class="mb-4 font-weight-bolder text-center main-headline-v4 ">Create Stunning Video Ads In Minutes!</div>
                                <p class="mb-4 sub-headline-v4">Effortless Video Ads Creation for Instagram, TikTok, and Facebook <br /> using AI-Powered Voiceovers, Captions, and more</p>

                                    <div class="cta-btn"style={{margin: 'auto', fontSize: 19}} >
                                        <a href="/login" class="text-decoration-none text-white">Start here <i class="bi bi-arrow-right"></i></a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <section class="examples-section">
            <div class="examples-section-heading">Here are some example videos</div>
                <div class='row sm-video-container'>
                    <div class='col-3' style={{display: "flex", fontSize: '3em', height: '100%', color: '#36454F', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{cursor: 'pointer'}} onClick={prevVideo}>
                            <i class="bi bi-arrow-left-circle-fill"></i>
                        </span>
                    </div>
                    <div class='col-6' style={{display: "flex", height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <video 
                            preload='auto'
                            controls={true}
                            style={{  height: '100%',  border: '1px solid #eee', borderRadius: 15, backgroundColor: 'white'}}
                            src={videoSamples[currentVideoIndex % videoSamples.length].url}
                            poster={videoSamples[currentVideoIndex % videoSamples.length].poster}
                        />
                    </div>
                    <div class='col-3' style={{display: "flex", fontSize: '3em', height: '100%', color: '#36454F', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{cursor: 'pointer'}} onClick={nextVideo}>
                            <i class="bi bi-arrow-right-circle-fill"></i>
                        </span>
                    </div>
                </div>
            </section> */}

            <section id='hiw-section-id' class="product-info-section">

                <div class="product-info row">
                    <div class="product-info-container col-6">
                        <div class="product-info-headline">
                            How it works?
                        </div>
                        <div class="product-info-detail">
                            <table class='product-info-table'>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>1</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Enter Your Script:</span> Type or paste your video script into our easy-to-use text input. </td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>2</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Select Your Voiceover:</span> Choose from a range of AI-generated voices for your video.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>3</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Customize Your Captions:</span> Pick from our stylish caption options to make your video stand out.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>4</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Add Visuals:</span> Browse our stock library or upload your own images and videos.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>4</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Edit & Finalize:</span> Use our powerful editor to polish your video to perfection.</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div class="product-info-feature-container col-6">
                        <div class="product-info-demo-wrapper">
                            <img
                                width='100%'
                                style={{ margin: 'auto', borderRadius: 10 }}
                                src="https://res.cloudinary.com/dlmsxlf2n/image/upload/v1719043058/Diveo/Assets/images/workflow-preview-img.png"
                            />

                        </div>
                    </div>

                </div>

            </section>
            <section id='feature-section-id' class="feature-section">
                <div class="section-heading">The ultimate tool for crafting impactful social media video ads.</div>
                <div class="feature-container">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-robot"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Text to Video AI</div>
                            <div class="feature-details">
                                Create videos just using the text using AI.
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-aspect-ratio-fill"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Multiple Formats</div>
                            <div class="feature-details">Craft videos perfectly tailored for any screen size or aspect ratio.</div>
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-collection-fill"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Stock Assets Library</div>
                            <div class="feature-details">Access a vast content library with millions of stock images and videos.</div>
                        </div>

                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-crop"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Crop Video</div>
                            <div class="feature-details">Quickly crop your video to the exact scene or section you want.</div>
                        </div>

                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-fonts"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Text on Video</div>
                            <div class="feature-details">Customise text using different styles and easy drag-and-drop feature.</div>
                        </div>

                    </div>

                    <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-music-note-beamed"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Add Audio</div>
                            <div class="feature-details">Pick an audio track that suits your video's mood.</div>
                        </div>

                    </div>

                </div>
            </section>
            <section class="pricing-section">
                <PricingPage />
            </section>
            <section class="final-cta-section">
                <div class="section-heading">Create powerful videos, faster. No prior video editing experience required.</div>
                <div class="section-sub-heading">Our professional-grade templates and features make it quick and easy to create videos for everyone.</div>
                <div class="cta-btn" style={{margin: 'auto'}}>
                        <a href="/login" class="text-decoration-none text-white">Try for free</a>
                </div>
            </section>

            <Footer />

        </div>
    )
}

export default Main
