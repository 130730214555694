import { store } from "../redux/store";
import { fabric } from "fabric";
import { updateObjectInfo } from "../redux/storySlice";
import {
  addOrReplaceVideo,
  renderObjectsFromConfig,
  addObjectsFromTimeline,
} from "../utils/renderUtils";
import { CustomTextbox } from "../constants/customFabric";

const playerModes = {
  PLAYER: "player",
  PREVIEW: "preview",
  SCENE_TRACKER: "scene-tracker",
  RENDER_CONTROL: "render-control",
};

const isVideoReady = (id, videoRef) => {
  return new Promise((resolve) => {
    console.log("VIDEO:", videoRef.current[id]);
    if (videoRef.current[id]) {
      var handle = setInterval(() => {
        var video = videoRef.current[id];
        if (video.readyState == 4) {
          console.log("State ==>", video.readyState);
          clearInterval(handle);
          console.log("VIDEO READY!!");
          resolve();
        }
      }, 100);
    } else {
      resolve();
    }
  });
};

function isClipObjPresentInConfig(renderConfig) {
  renderConfig.map((config) => {
    if (config.id == "clip-path") {
      return true;
    }
  });
  return false;
}

const composeScene = async (
  sceneInfo,
  audioInfo,
  timeline,
  canvas,
  containerParams,
  clipPathRef,
  videoRef,
  textRef,
  captionStyle,
  wordTimestamps,
  wordsIndexTracker,
  wordIndexInString,
  processType
) => {
  console.log("Render Scene: ", sceneInfo);
  var renderConfig = [];

  // var canvas = fabricRef.current[sceneInfo.id]
  if (canvas.getObjects()) {
    if (processType == playerModes.PLAYER) {
      canvas.remove(
        ...canvas.getObjects().filter((obj) => {
          return obj.id != "clip-path";
        })
      );
    } else {
      canvas.remove(...canvas.getObjects().concat());
    }
  }

  var index = 1;

  console.log("Scene Info: ", sceneInfo);

  const startTime = sceneInfo.start;

  await addObjectsFromTimeline(
    timeline,
    sceneInfo.id,
    startTime,
    containerParams,
    canvas,
    videoRef,
    clipPathRef,
    index++,
    renderConfig
  );

  if (!wordTimestamps.current.length) {
    wordTimestamps.current = processJson2(audioInfo, wordTimestamps);
  }

  console.log("WORD LEVEL TIMESTAMPS: ", wordTimestamps.current);

  // wordsIndexTracker.current = wordTimestamps.current.findIndex(
  //   (obj) => obj.start == startTime
  // );

  wordsIndexTracker.current = getWordIndexFromTimestampedData(
    wordTimestamps,
    startTime
  );

  if (wordIndexInString) {
    wordIndexInString.current = 0;
  }

  console.log("WORD TRACKER INDEX: ", wordsIndexTracker.current);

  var shadow = new fabric.Shadow({
    color: "#000",
    blur: 5,
  });

  // var wordIndex = wordsIndexTracker.current
  const wordIndex = wordsIndexTracker.current;
  var displayText = wordTimestamps.current[wordIndex]?.text.join(" ");

  const maxWidth = containerParams.viewWidth * 0.8;
  console.log("Cont Params: ", containerParams);
  console.log("MAX WIDTH 1 ==>", maxWidth);
  const textbox = new CustomTextbox("", {
    ...captionStyle,
    lockMovementX: true,
    lockMovementY: true,
    id: "cpt-txt",
    maxWidth: maxWidth,
    text: displayText,
    left: canvas.getWidth() / 2,
    originX: "center",
  });

  if (processType == playerModes.PREVIEW) {
    textRef.current[sceneInfo.id] = textbox;
  } else {
    textRef.current = textbox;
  }

  renderConfig.push({
    id: "m-txt",
    obj: textbox,
    posOffset: false,
    index: index++,
  });

  await renderObjectsFromConfig(
    renderConfig,
    containerParams,
    canvas,
    processType
  );

  await isVideoReady(sceneInfo.id, videoRef);
};

function getWordIndexFromTimestampedData(wordTimestamps, currentTime) {
  for (let i = 0; i < wordTimestamps.current.length; i++) {
    const wordObj = wordTimestamps.current[i];
    if (i == 0 && currentTime < wordTimestamps.current[0].start) {
      return 0;
    }
    if (
      i == wordTimestamps.current.length - 1 &&
      wordObj.start <= currentTime
    ) {
      return i;
    }

    const nextWordObj = wordTimestamps.current[i + 1];
    if (wordObj.start <= currentTime && nextWordObj.start > currentTime) {
      return i;
    }
  }
  return 0;
}

const processJson2 = (audioInfo, wordTimestamps) => {
  var phraseArray = [];
  var phraseStart = 0;
  var phraseEnd = 0;
  var wordCount = 0;

  var sentenceRangeMap = audioInfo.sentenceRangeMap;

  var words = audioInfo.wordLevelTimestamps;
  console.log("Words: ", words);
  var sentenceNumber = 0;
  var sentenceBoundary;
  var wordLevelInfo = [];

  for (let i = 0; i < words.length; i++) {
    var word = words[i];
    console.log("------------- x --------------");
    console.log("Index: ", i);
    console.log("Word: ", word);
    // offset = word.Offset / 10000000;
    // duration = word.Duration / 10000000;
    if (wordCount == 0) {
      phraseStart = word.wordStartTime;
    }

    const isLastWord = word.isLastWord;

    // sentenceBoundary =
    //   sentenceNumber < sentenceRangeMap.length
    //     ? sentenceRangeMap[sentenceNumber].end
    //     : 0;

    console.log("Sentence Number: ", sentenceNumber);
    console.log("Sentence Start: ", sentenceRangeMap[sentenceNumber].start);
    // console.log("Sentence Boundary: ", sentenceBoundary);

    phraseArray.push(word.word);

    var wordStartIndex = phraseArray.join(" ").indexOf(word.word);
    var modifiedWord = {
      ...word,
      startIndex: wordStartIndex,
      endIndex: wordStartIndex + word.word.length,
    };

    wordLevelInfo.push(modifiedWord);
    console.log("Phrase Array: ", phraseArray);
    wordCount++;

    if (wordCount == 3 || isLastWord) {
      phraseEnd = word.wordEndTime;
      wordTimestamps.current.push({
        start: phraseStart,
        end: phraseEnd,
        text: phraseArray,
        words: wordLevelInfo,
      });
      phraseArray = [];
      wordLevelInfo = [];
      wordCount = 0;
    }

    if (isLastWord) {
      sentenceNumber++;
    }
  }
  console.log("Phrase Timestamp: ", wordTimestamps.current);
  return wordTimestamps.current;
};

function updateObjectCoords(objId, timeline, coords) {
  var obj = getObjectFromId(objId, timeline);
  if (obj) {
    var newObjectInfo = { ...obj, pos: coords };
    console.log("NEW OBJECT INFO: ", newObjectInfo);
    store.dispatch(updateObjectInfo({ id: objId, objInfo: newObjectInfo }));
  }
}

function updateObjectScaleValue(objId, timeline, scale) {
  var obj = getObjectFromId(objId, timeline);
  if (obj) {
    var newObjectInfo = { ...obj, scaleX: scale.x, scaleY: scale.y };
    console.log("NEW OBJECT INFO: ", newObjectInfo);
    store.dispatch(updateObjectInfo({ id: objId, objInfo: newObjectInfo }));
  }
}

function getObjectFromId(objId, timeline) {
  if (objId) {
    for (let i = 0; i < timeline.length; i++) {
      var obj = timeline[i];
      if (obj.id == objId) {
        return obj;
      }
    }
    return;
  }
}

function isVideoAvailableForScene(scene, timeline) {
  if (scene) {
    var objs = timeline.filter((obj) => {
      return scene.start >= obj.startTime && scene.end <= obj.endTime;
    });

    console.log("SCENE: ", scene);
    console.log("OBJS:==> ", objs);
    var isVideoAvailable = false;

    objs.map((obj) => {
      if (obj.type == "video") {
        isVideoAvailable = true;
      }
    });
    console.log(`VIDEO AVAILABLE FOR SCENE ${scene.id} ::`, isVideoAvailable);
    return isVideoAvailable;
  }
}

export {
  playerModes,
  composeScene,
  updateObjectCoords,
  updateObjectScaleValue,
  isVideoAvailableForScene,
  getObjectFromId,
  getWordIndexFromTimestampedData,
};
