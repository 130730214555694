import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import EditorView from "./components/EditorView";
import Auth from "./components/Auth";
import Logout from "./components/Logout";
import LoginWithEmail from "./components/LoginWithEmail";
import RenderControl from "./components/RenderControl";
import { useIsFontListLoaded } from "./hooks/useIsFontListLoaded";
import ExportView from "./components/ExportView";
import HomePage from "./pages/HomePage";
import PricingPage from "./pages/PricingPage";
import PrivateRoute from "./components/PrivateRoutes";
import Redirect from "./components/Redirect";
import VideoTrim from "./components/VideoTrim";

const App = (props) => {
  const isFontListLoaded = useIsFontListLoaded();

  return (
    isFontListLoaded && (
      <React.Fragment>
        <Router>
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/login-with-email" element={<LoginWithEmail />} />

            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<Main />} exact={true} />
            <Route path="/pricing" element={<PricingPage />} />

            <Route path="/render" element={<RenderControl />} exact={true} />
            <Route path="/checkout/new" element={<Redirect />} exact={true} />

            <Route path="trim" element={<VideoTrim />} exact={true} />

            <Route
              path="/edit/:projectId"
              element={
                <PrivateRoute>
                  <EditorView />
                </PrivateRoute>
              }
            />

            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <HomePage activeMenuIndex={0} />
                </PrivateRoute>
              }
            />
            <Route
              path="/export/:jobId"
              element={
                <PrivateRoute>
                  <ExportView />
                </PrivateRoute>
              }
            />

            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <HomePage activeMenuIndex={1} />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </React.Fragment>
    )
  );
};

export default App;
