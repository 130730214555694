import { createSlice } from "@reduxjs/toolkit";

const storySlice = createSlice({
  name: "storyReducer",
  initialState: {
    text: null,
    sceneInfoArray: [],
    timelineInfo: [],
    captionStyle: null,
    activeSceneIndex: 0,
    actionLog: [],
    audioInfo: null,
    settings: {
      aspectRatio: "16/9",
      fps: 30,
    },
    assets: [],
    selectedObjectId: null,
    menuIndexSelected: 0,
    alertDialogInfo: { isOpen: false },
    isSettingsModalActive: false,
    isVideoEditModalActive: false,
    loading: false,
  },
  reducers: {
    initNewState(state, action) {
      state.sceneInfoArray = [];
      state.timelineInfo = [];
      state.actionLog = [];
      state.audioInfo = null;
      state.captionStyle = null;
      state.assets = [];
      state.activeSceneIndex = 0;
      state.selectedObjectId = null;
      state.menuIndexSelected = 0;
    },
    setActiveSceneIndex(state, action) {
      state.activeSceneIndex = action.payload.index;
    },
    updateSelectedObjId(state, action) {
      state.selectedObjectId = action.payload.objId;
    },
    setMenuIndexSelected(state, action) {
      state.menuIndexSelected = action.payload.menuIndexSelected;
    },
    updateAssetsInfo(state, action) {
      state.assets = action.payload.assets;
    },
    setAudioInfo(state, action) {
      state.audioInfo = action.payload.audioInfo;
    },
    setCaptionStyle(state, action) {
      state.captionStyle = action.payload.captionStyle;
    },
    updateThumbnailForScene(state, action) {
      state.assets[action.payload.sceneId].thumbnail = action.payload.thumbnail;
    },
    setSceneInfo(state, action) {
      state.sceneInfoArray = action.payload.sceneInfo;
    },
    updateTimeline(state, action) {
      state.timelineInfo = action.payload.timelineInfo;
    },
    updateObjectInTimeline(state, action) {
      state.timelineInfo[action.payload.objIndex] = action.payload.objInfo;
    },
    setAspectRatio(state, action) {
      state.settings.aspectRatio = action.payload.aspectRatio;
    },
    updateSettings(state, action) {
      state.settings = action.payload.settings;
    },
    setAlertDialogInfo(state, action) {
      state.alertDialogInfo = action.payload.info;
    },
    setSettingsModalActive(state, action) {
      state.isSettingsModalActive = action.payload.isActive;
    },
    setVideoEditModalActive(state, action) {
      state.isVideoEditModalActive = action.payload.isActive;
    },
    updateObjectInfo(state, action) {
      state.timelineInfo[action.payload.id] = action.payload.objInfo;
    },
    updateActionLog(state, action) {
      state.actionLog = action.payload.actionLog;
    },
    updateLatestActionStatus(state, action) {
      state.actionLog[0].status = action.payload.status;
    },
    setLoading(state, action) {
      state.loading = action.payload.isLoading;
    },
    clearAndApplyNewProject(state, action) {
      state.timelineInfo = action.payload.timelineInfo;
      state.settings = action.payload.settings;
      state.sceneInfoArray = action.payload.sceneInfoArray;
      state.captionStyle = action.payload.captionStyle;
      state.audioInfo = action.payload.audioInfo;
      state.alertDialogInfo = {
        isOpen: false,
      };
      state.assets = [];
      state.actionLog = [];
      state.menuIndexSelected = 0;
      state.selectedObjectId = null;
      state.activeSceneIndex = 0;
      state.isSettingsModalActive = false;
      state.isVideoEditModalActive = false;
    },
  },
});

const { actions, reducer } = storySlice;

export const {
  initNewState,
  setActiveSceneIndex,
  updateSelectedObjId,
  setMenuIndexSelected,
  updateAssetsInfo,
  setAudioInfo,
  setCaptionStyle,
  updateThumbnailForScene,
  setSceneInfo,
  updateTimeline,
  updateObjectInTimeline,
  setAspectRatio,
  updateSettings,
  setAlertDialogInfo,
  setSettingsModalActive,
  setVideoEditModalActive,
  updateObjectInfo,
  updateActionLog,
  updateLatestActionStatus,
  setLoading,
  clearAndApplyNewProject,
} = actions;

export default reducer;
