import React from 'react'
import '../../css/toolbar.css';
import { menuIndex } from '../../constants/editorConfig';
import { setMenuIndexSelected, setVideoEditModalActive } from '../../redux/storySlice'
import { useDispatch, useSelector } from 'react-redux'
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const MediaEditOptions = () => {

    const dispatch = useDispatch()

    const openMediaMenu = () => {
        dispatch(setMenuIndexSelected({menuIndexSelected: menuIndex.MEDIA_MENU_INDEX}))
    }

    const openVideoEditModal = () => {
        dispatch(setVideoEditModalActive({isActive: true}))
    }

    return (
        <div style={{width: '100%', marginTop: 5}}>
            <span className='edit-btn' onClick={openMediaMenu}>
                <i class="bi bi-arrow-left-right" style={{fontSize: 15, marginRight: 3}}></i>  Replace Media
            </span>
            <span className='edit-btn' style={{marginLeft:10}} onClick={openVideoEditModal}>
                <ContentCutIcon style={{fontSize: 15}}/> Trim
            </span>
        </div>
    )
}

export default MediaEditOptions
