import { updateTimeline, updateObjectInTimeline } from "../redux/storySlice";
import { store } from "../redux/store";

const updateTimelineInfo = (timelineInfo, targetObjId, objInfo) => {
  var newTimelineInfo = [...timelineInfo];

  console.log("Old Timeline Info: ", timelineInfo);
  var objectIndex = -1;
  timelineInfo.map((obj) => {
    console.log("Obj: ", obj);
    if (obj.id == targetObjId) {
      objectIndex = obj.id;
    }
  });

  const newInfo = {
    url: objInfo.url,
    height: objInfo.height,
    width: objInfo.width,
    duration: objInfo.duration,
  };
  if (objectIndex >= 0) {
    var obj = newTimelineInfo[objectIndex];
    newTimelineInfo[objectIndex] = {
      ...obj,
      info: newInfo,
      videoStartTime: null,
      pos: null,
      scaleX: null,
      scaleY: null,
    };
  } else {
    // New Object is Created
    var newObjInfo = {
      info: newInfo,
      id: objInfo.id,
      sceneId: objInfo.sceneId,
      videoStartTime: objInfo.videoStartTime,
      startTime: objInfo.startTime,
      endTime: objInfo.endTime,
      type: objInfo.type,
    };
    newTimelineInfo.push(newObjInfo);
  }

  store.dispatch(updateTimeline({ timelineInfo: newTimelineInfo }));

  console.log("New Timeline Info: ", newTimelineInfo);
};

function updateObjInTimeline(timelineInfo, objId, objInfo) {
  var objIndex = -1;
  var oldObjInfo;
  timelineInfo.map((obj, index) => {
    console.log("Obj: ", obj);
    if (obj.id == objId) {
      objIndex = index;
      oldObjInfo = obj;
    }
  });

  if (objIndex >= 0) {
    var newObjectInfo = { ...oldObjInfo, ...objInfo };
    store.dispatch(
      updateObjectInTimeline({ objIndex: objIndex, objInfo: newObjectInfo })
    );
  }
}

export { updateTimelineInfo, updateObjInTimeline };
