import React from 'react'
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material'
import VideoTrim from '../VideoTrim';

const VideoEditModalView = ({open, handleClose}) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogContent style={{position: 'relative'}}>
                    <VideoTrim handleClose={handleClose} />
                    <div style={{position: 'absolute', right: 15, top: 15, fontSize: 18, cursor: 'pointer' }} onClick={handleClose}><i class="bi bi-x-lg"></i></div>
                </DialogContent>
        
        </Dialog>
    
    </>
    )
}

export default VideoEditModalView
