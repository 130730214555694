import React, {useState, useEffect, useRef} from 'react'
import { fabric } from "fabric";
import { useDispatch, useSelector } from 'react-redux'
import {
    getPreviewContainerDimensions,
} from "../constants/canvasSettings";
import { setActiveSceneIndex, updateAssetsInfo, updateThumbnailForScene } from '../redux/storySlice';
import { playerModes, composeScene } from "../utils/sceneUtils";  

const SceneTracker = () => {
    const dispatch = useDispatch()

    const wordTimestamps = useRef([])
    const wordsIndexTracker = useRef()

    const fabricRef = useRef([]);
    const clipPathRef = useRef(null);


    const videoRef = useRef({});
    const textRef = useRef();

    const [dims, setDims] = useState({height: 70, width: 70})

    const [currentThumbnail, setCurrentThumbnail] = useState()
    const [containerParams, setContainerParams] = useState();
    const [scenes, setScenes] = useState([])
    const [images, setImages] = useState([])
    const thumbnailRef = useRef([])
    

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )
    
    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    const assets = useSelector(
        (state) => state.storyReducer.assets
    )

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    const sceneInfoArray = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const actionLog = useSelector(
        (state) => state.storyReducer.actionLog
    )

    useEffect(() => {
       if(sceneInfoArray){
            setScenes(sceneInfoArray)
            wordTimestamps.current = [];
       }
    }, [sceneInfoArray])

    useEffect(() => {
        if(storySettings.aspectRatio){
            switch(storySettings.aspectRatio){
                case '16/9':
                    setDims({height: 70, width: 124.4})
                    break;
                case '1/1':
                    setDims({height: 70, width: 70})
                    break;
                case '9/16':
                    setDims({height: 70, width: 39.3})
                    break;
                default:
                    setDims({height: 70, width: 124.4})
            }
            const containerSettings = getPreviewContainerDimensions(storySettings.aspectRatio ?? '1/1');
            setContainerParams(containerSettings);

            dispatch(updateAssetsInfo({assets:[]}))
            thumbnailRef.current = []
            
        }
        
    }, [storySettings.aspectRatio])

    useEffect(() => {
        if(containerParams && scenes && scenes.length){
            startThumbnailGenerationProcess()
        }
    }, [scenes, containerParams])

    useEffect(() => {
        if(actionLog[0]?.status == 1 && containerParams && scenes.length > 0){
            if(assets.length == scenes.length){
                updateThumbnail()
            }

        }
    }, [actionLog, captionStyle, timeline, scenes, activeSceneIndex])

    // useEffect(() => {
    //     const containerSettings = getPreviewContainerDimensions(storySettings.aspectRatio ?? '1/1');
    //     setContainerParams(containerSettings);
    //     console.log("VIDOE REFS: ", videoRef);
    // }, []);

    const updateThumbnail = async () => {
        var thumbnail = await updateActiveThumbnail()
        if(thumbnail){
            dispatch(updateThumbnailForScene({sceneId: activeSceneIndex, thumbnail: thumbnail}))
        }
    }

    const startThumbnailGenerationProcess = async () => {
        await generateThumbnails()
        console.log('FINAL THUMBNAILS: ', thumbnailRef.current)
        dispatch(updateAssetsInfo({assets: thumbnailRef.current}))
    }


    const isVideoReady = (id) => {
        return new Promise(resolve => {
            console.log('VIDEO:' ,videoRef.current[id])
            if(videoRef.current[id]){
                var handle = setInterval(() => {
                    var video = videoRef.current[id]
                    if( video.readyState == 4){
                        console.log('State ==>', video.readyState)
                        clearInterval(handle);
                        console.log('VIDEO READY!!')
                        resolve()
                    }
                }, 100);
              
            }else{
                resolve()
            }
        });
    }

    const generateThumbnails = async () => {

        return Promise.all(scenes.map((scene, index) => {
            return new Promise(async (resolve, reject) => {
                console.log('SCENE ====> ', scene)
                console.log('TIMELINE: ', timeline)
                const offScreenCanvas = document.createElement('canvas');
                offScreenCanvas.width = containerParams.viewWidth; // Thumbnail width
                offScreenCanvas.height = containerParams.viewHeight; // Thumbnail height
          
                const fabricCanvas = new fabric.Canvas(offScreenCanvas, {preserveObjectStacking: true, width: containerParams.viewWidth, height: containerParams.viewHeight, backgroundColor: "#ddd",});
        
                fabricRef.current[scene.id] = fabricCanvas
        
                // await composeScene(scene, fabricRef.current[scene.id])
                await composeScene(
                    scene,
                    audioInfo,
                    timeline,
                    fabricRef.current[scene.id],
                    containerParams,
                    clipPathRef,
                    videoRef,
                    textRef,
                    captionStyle,
                    wordTimestamps,
                    wordsIndexTracker,
                    null,
                    playerModes.SCENE_TRACKER
                )
    
                await isVideoReady(scene.id)
        
                console.log('Video: ', videoRef.current[scene.id])
        
                console.log('Capturing Canvas Snapshot')
        
                fabricRef.current[scene.id].requestRenderAll()
        
                const dataUrl = fabricRef.current[scene.id].toDataURL('image/png');
    
                var thumbnails = [...thumbnailRef.current]
                thumbnails[scene.id] = { thumbnail: dataUrl }
                thumbnailRef.current = thumbnails
        
                console.log('THUMBNAILS: ', images)

                offScreenCanvas.remove()

                resolve()
            })
            
        }));
    };

    const updateActiveThumbnail = async () => {
        return new Promise(async (resolve, reject) => {
            console.log('Updating Thumbnail for Scene: ', activeSceneIndex)
            const offScreenCanvas = document.createElement('canvas');
            offScreenCanvas.width = containerParams.viewWidth; // Thumbnail width
            offScreenCanvas.height = containerParams.viewHeight; // Thumbnail height
      
            const fabricCanvas = new fabric.Canvas(offScreenCanvas, {preserveObjectStacking: true, width: containerParams.viewWidth, height: containerParams.viewHeight, backgroundColor: "#ddd",});
            var scene = scenes[activeSceneIndex]

            fabricRef.current[scene.id] = fabricCanvas
    
            console.log('TEXT REF ==> ', textRef)
            await composeScene(
                scene,
                audioInfo,
                timeline,
                fabricRef.current[scene.id],
                containerParams,
                clipPathRef ,
                videoRef,
                textRef,
                captionStyle,
                wordTimestamps,
                wordsIndexTracker,
                null,
                playerModes.SCENE_TRACKER
            )

            await isVideoReady(scene.id)

            if( videoRef.current[scene.id]){
                await videoRef.current[scene.id].play()
                await videoRef.current[scene.id].pause()
    
            }
          
            console.log('Video: ', videoRef.current[scene.id])
        
            console.log('Capturing Canvas Snapshot')
    
            fabricRef.current[scene.id].requestRenderAll()
            
            const dataUrl = fabricRef.current[scene.id].toDataURL('image/png');
    
            // console.log('DATA URL: ', dataUrl)
            offScreenCanvas.remove()
    
            setCurrentThumbnail(dataUrl)
            resolve(dataUrl)
    
        })
    }

    const changeActiveScene = (sceneIndex) => {
        dispatch(setActiveSceneIndex({index: sceneIndex}))
    }

    return (
        <>
            {
                containerParams &&  
                    <div style={{display: 'block', width: '100%', backgroundColor: '#fff', paddingTop: 8, paddingInline: 30, height: 90, borderRadius: 3, overflowX: 'scroll', whiteSpace: 'nowrap'}}>
                       
                        {
                            assets.map((obj, index) =>
                                
                                <div style={{display: 'inline-block', position: 'relative', border: activeSceneIndex == index ? '2px solid rgba(91, 68, 237, 0.8)': '1px solid #ddd', width: 126, height: 76, backgroundColor: '#f5f5f5', marginRight: 15, borderRadius: 5, cursor: 'pointer'}} onClick={() => changeActiveScene(index)}>
                                    <img 
                                        style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', border: '1px solid #ccc', }}
                                        height={dims.height}
                                        width={dims.width}
                                        src={obj?.thumbnail}   
                                    />
                                </div>
                               
                            )
                        } 
                        
                               
                    </div>
            }
          
        </>
    )
}

export default SceneTracker
