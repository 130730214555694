import React, {useState, useEffect} from 'react'
import '../css/editor.css';
import { useDispatch, useSelector } from 'react-redux'
import UploadBox from './UploadBox'
import ReactLoading from 'react-loading';
import { updateActionLog } from '../redux/storySlice'
import { db } from "../configs/firebaseConfig";
import { actionType, actionStatus } from '../utils/actionManager';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { isVideoAvailableForScene } from '../utils/sceneUtils';

const UploadView = () => {
    const dispatch = useDispatch()
    const authUser = useIsAuthenticated()

    const [mediaItems, setMediaItems] = useState()
    const [loading, setLoading] = useState(false)

    const [isErrorDisplayed, setErrorDisplayed] = useState(false)
    const [error, setError] = useState('You can only upload video files for now.')

    const selectedObjectId = useSelector(
        (state) => state.storyReducer.selectedObjectId
    )

    const actionLog = useSelector(
        (state) => state.storyReducer.actionLog
    )

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    const sceneInfo = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    useEffect(() => {
        if(mediaItems){
            console.log('Setting Loading => False')
            setLoading(false);
        }
    }, [mediaItems])

    useEffect(() => {
        var unsubscribe;
        console.log('view auth: ', authUser)
        if(authUser){
            const collectionRef = collection(db, "users", authUser.uid, "assets");
            const querySnapshot = query(collectionRef, orderBy("created", "desc"));
           
            console.log("Query Snapshot: ", querySnapshot);
            unsubscribe = onSnapshot(querySnapshot, (docs) => {
                let assets = [];
                docs.forEach((doc) => {
                    assets.push({ ...doc.data(), id: doc.id });
                });
                updateMediaItems(assets);
            });
            
        }
        
        return () => {
            if(authUser){unsubscribe()}
        } 
    }, [authUser])

    const updateMediaItems = (assets) => {
        console.log('UPDATING MEDIA ITEMS: ', assets)
        setMediaItems(assets)
    }

    const handleMediaOptionClick = (obj) => {
        // Replace Media only if Media Object is selected
        console.log('Selected OBject: ', selectedObjectId)
        var scene = sceneInfo[activeSceneIndex]

    
        if(selectedObjectId != null){
            console.log('Selected Media Info: ', obj)

            console.log('Action Log: ', actionLog)
            var actionLogRef = actionLog
    
            const action = {
                type: actionType.REPLACE_MEDIA,
                target: selectedObjectId,
                info: {...obj, created: null},
                status: actionStatus.PROCESS
            }
    
            dispatch(
                updateActionLog({actionLog: [action, ...actionLogRef]})
            )
        }else if(!isVideoAvailableForScene(sceneInfo[activeSceneIndex], timeline)){
            console.log('Selected Media Info: ', obj)

            console.log('Action Log: ', actionLog)
            var actionLogRef = actionLog

            const info = {...obj, type: 'video', id: scene.id, sceneId: scene.id, startTime: scene.start, endTime: scene.end, created: null}
    
            const action = {
                type: actionType.ADD_MEDIA,
                target: activeSceneIndex,
                oldState: obj,
                newState: info,
                info: info,
                status: actionStatus.PROCESS
            }
    
            dispatch(
                updateActionLog({actionLog: [action, ...actionLogRef]})
            )
        }
       
    }

    const closeErrorDisplay = () => {
        setErrorDisplayed(false)
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{ display: isErrorDisplayed ? 'block': 'none',padding: 8, width: '90%', margin: 'auto', marginTop: 5, borderRadius: 5, backgroundColor: '#ED4337', color: '#fff'}}>
                <span style={{width: '85%'}}>{error}</span>
                <div style={{float: 'right', textAlign: 'right', fontSize: 18, width: '10%', cursor: 'pointer'}} onClick={closeErrorDisplay}>
                    <i class="bi bi-x"></i>
                </div>
            </div>
            <UploadBox authUser={authUser} setLoading={setLoading} setErrorDisplayed={setErrorDisplayed}/>
            {
                <div className='toolbox-items-container' style={{padding: 20}}>
                    {
                        loading && (
                            <div style={{width: '10%', margin: 'auto', textAlign: 'center', marginBottom: 15}}>
                                <ReactLoading type='spokes' color="#988AF1" height={16} width={16} />
                            </div>
                            
                        )
                    }
                    {
                        mediaItems && mediaItems.length ? mediaItems.map((obj, index) =>  
                            <div style={{display: 'inline-block', position: 'relative', border: '1px solid #ddd', backgroundColor: '#ddd', width: 126, height: 76, margin: 10, borderRadius: 5, cursor: 'pointer'}}>
                                    <img 
                                        crossOrigin="anonymous"
                                        data-id={index}
                                        height={76}
                                        style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 5, border: '1px solid #ccc', }}
                                        src={obj?.thumbnail}   
                                        onClick={() => handleMediaOptionClick(obj)}
                                    />
                            </div>) :
                            <div style={{width: '100%', textAlign: 'center', fontStyle: 'italic', marginTop: 10, color: '#4a4a4a'}}>No media items to show <br/> Upload a file.</div> 
                    }
                </div>
            }
        </div>
    )
}

export default UploadView
